import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
import { PortfolioChart } from "../../../components/widgets/portfolio-ref";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/leveraged-etf-portfolios-2/"
          }}>{`Insights: Aggressively Leveraged ETF Portfolios`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Volatility_(finance)"
          }}>{`Volatility`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://www.cboe.com/vix"
          }}>{`VIX`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.investopedia.com/terms/c/contango.asp"
          }}>{`Contango`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.investopedia.com/terms/b/backwardation.asp"
          }}>{`Backwardation`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://fred.stlouisfed.org/series/SAHMREALTIME"
          }}>{`Sahm-Rule`}</a></li>
      </ul>
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-pick-me-up/"
          }}>{`TT's Pick Me Up`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-rain-or-shine/"
          }}>{`TT's Rain or Shine`}</a></li>
      </ul>
    </Aside>
    <p>{`Since its trough on March 9, 2009, when the S&P 500 closed at 676.53, the stock market has almost quintupled in value. That's an annualized growth rate of more than 15%! And while we are thinking about these tasty returns, the painful losses of the 2008 recession quickly disappear in the rearview mirror. A fearless investor might ask: can we make even more? The answer is yes, but the devil is in the details.`}</p>
    <h2>{`Applying Leverage`}</h2>
    <p>{`The idea is to further boost the stock market's returns by applying leverage. In a nutshell, we borrow money, taking advantage of the low-interest rates. Next, we invest this money in the stock market and pocket the difference between the stock gains and the interest paid. Voila, we have just levered up our returns.`}</p>
    <p>{`We can apply leverage in multiple ways. Our broker happily borrows us money in a margin account, allowing us to leverage up by a factor of two or even more, depending on the account type. But how about these leveraged ETFs carrying terms like `}<em parentName="p">{`UltraPro`}</em>{` or `}<em parentName="p">{`Bull 3x`}</em>{` in their names?`}</p>
    <div css={`display:flex;column-gap:1em;`}><div css={`flex:1;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "481px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/4fb627d74a49d88f2b99a69064165526/d024a/day-1.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "60%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABUUlEQVQoz4WSu0oDQRSG8yoiFhoLjSgIGvQFRLAIKFiLWFikiIRNTFBbxVIQG98gaCNeEhCMimIRvCEYCZu42Us2m+zO7pmZI1nQJhn3787wfzPnzH9CAMB6iVLaNE3LsiilTKAQYwy7xTkiWo4HrvNb9VBP2PeC41VKb2XTJuz3KBDmnRLkV3N/vpqZiiXzi1sPz2WTAWk0mgAghv3+uNs2due0eF8tO7MkXU2sFGKbxS+5rqkqIa4YZhQR3dJ5PTGkSyPf2eiCdDO9XoyuXZ7dK4hIGQ+Cn07VxKCaGjNSkZOdxLJ0Mbp6m7uuBsAUvJZNLPlDy05qG2E1PW6nhyuZ2YPtvU+F+GOJYQBQalW9RchjTktF1Hi/Eh8wkmF+d/z3l0FR+dfT2rtTOHLyh1R+4V1v/pNzx8sQwY+XcuGWCGDEtu0Yuu61GlbTFCwY/gBSR5ZrwoZMfwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "leveraged ETF mechanics",
                "title": "leveraged ETF mechanics",
                "src": "/static/4fb627d74a49d88f2b99a69064165526/d024a/day-1.png",
                "srcSet": ["/static/4fb627d74a49d88f2b99a69064165526/5a46d/day-1.png 300w", "/static/4fb627d74a49d88f2b99a69064165526/d024a/day-1.png 481w"],
                "sizes": "(max-width: 481px) 100vw, 481px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`flex:1;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "485px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/287aa202d16bb4c1be8560642f233d1b/44c61/day-2-before.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABYklEQVQoz4WSv0vDQBTH+4c4uDg4WERR2oKD4CaIi7iL4I/BpRX8QfpDdHAVxcFNEAQnN4UWqyjtUBREaF0CaVGoSS93SZrkcpc7SepSTeJ3eHDc+9y7930vRghx+0UpRQgZugYAUFWo6zpCCGPMGPuVGXNdl/eLcQ6hqhtdZDnYNi3TUCEihPA/CoB/RDFtvTbEjma6vRe9yKJh5h3Jl4iOF+TsxLJwMy/Uqm+KT7Loyv61S+HJopIeVAqp1fzt5Prj3HZF+kRI7Wi6EQ77ZR2xpuzGgTAqF5JLueJ0upJcK1+WmpxTy3ZCYUYp5dyuP3R2RhRh3MjFT/ePpjIviZXSRbHl+UBZKGzbVluWYfsDHs4oW8MgO6YdpDbzV4mNakNCXkOM/W8Yrt+BvYScGdLSA++F2et7KcDr4FH5Sa4i2U/nZvmMNp+DwIg5M+8DvbUgIWTUklgWVgFwukiDahj8DdxYlR0InGugAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "how leveraged ETFs work",
                "title": "how leveraged ETFs work",
                "src": "/static/287aa202d16bb4c1be8560642f233d1b/44c61/day-2-before.png",
                "srcSet": ["/static/287aa202d16bb4c1be8560642f233d1b/5a46d/day-2-before.png 300w", "/static/287aa202d16bb4c1be8560642f233d1b/44c61/day-2-before.png 485w"],
                "sizes": "(max-width: 485px) 100vw, 485px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div></div>
    <div css={`display:flex;column-gap:1em;`}><div css={`flex:1;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "485px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/875c3b8a575f770bf52e0fbe61cddb30/44c61/day-2-after.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABVklEQVQoz42Ry0oCURzGfZWIFmWLRAoikh6gdpGboHXRJpAwitEmaif0AtKiNygiiOhiBKlBRUhERAnFqM3MuczF8VzDAduMOn27c/h+3/n/zxehlPKAMMa2bQNgQogsy0IYMcaCtgjnXAaEMbIcBzcJJa7jWBBhIUTQ1hX2faRJqk8vH9DxROcqFBbtI/mqoP05LTuV3LxOqvfP71BygrHFGOsN+7OJlg1ys0ZqoLYzs6hcTSwXFrLF7zqAEBBKe8O8HdyqnOvpEVMZq6uJeeVueq2YWL08KzeklIyLMPjxRE8PG0ocZGKne+tLykVspXR8q4XAnDHHI27t01AnjY2okRl3M1FtO5HfzVUbLX+tPjDnpv6DPeo9HBlKXE8NNlJDcGtUlA///jKsKj+e19+8m4NmIc+1VxF4s0/PbS+Xkvr1sk7cv2EpXdcDpklsaGPUHZXyF4/elYsRFw8gAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "how to use leverged ETFs",
                "title": "how to use leverged ETFs",
                "src": "/static/875c3b8a575f770bf52e0fbe61cddb30/44c61/day-2-after.png",
                "srcSet": ["/static/875c3b8a575f770bf52e0fbe61cddb30/5a46d/day-2-after.png 300w", "/static/875c3b8a575f770bf52e0fbe61cddb30/44c61/day-2-after.png 485w"],
                "sizes": "(max-width: 485px) 100vw, 485px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`flex:1;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "485px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/d52a34a44486a5d69e9b8eba9471d673/44c61/day-3-before.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABYklEQVQoz4WRQUsCQRTH/SwlFEQUaNClIIKIjkEFXqJbty6ZFbRaSR2CPoIfoEN07BKoBxNCLQ9FHoqiUnB3dmdmd2ZnZ2enVihyWfWd3uP9f7z3/i/CORfdwRiDEGKMgKYafoIhNBwnKOOcR4QQsjtc1zV0YBEKCXMYIZZpGFAILyATQoTAUnZ0DnHeHh5fdML80rZt13UHwZ5fOu91dL70dTi7nMonstX75zYyNNMifWHPH+JRBM4WQXKomZ1f2S9MbRbWMmVM+G+/Fyz8xVj9up0a1ZXJ5vFc4iC/sFOe2SrdVFXfjn+Xh8O0cqWmRjQlpqbjTyerldONfHr9o3bbuUkMWlv/1I+m1b0xLROHygTajVrpcam9/jnS0zBiYoAJKl0AJaZuD7eTUVWJ0bvLABkO2zbFCHFPilaDFnO0mBOthgx41ffPvlRIyTsfd8PIvrCUpkUNAJgJMYKhgh/4GyN5lVyTCgpWAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "manage risk of leveraged ETFs",
                "title": "manage risk of leveraged ETFs",
                "src": "/static/d52a34a44486a5d69e9b8eba9471d673/44c61/day-3-before.png",
                "srcSet": ["/static/d52a34a44486a5d69e9b8eba9471d673/5a46d/day-3-before.png 300w", "/static/d52a34a44486a5d69e9b8eba9471d673/44c61/day-3-before.png 485w"],
                "sizes": "(max-width: 485px) 100vw, 485px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div></div>
    <div css={`display:flex;column-gap:1em;`}><div css={`flex:1;`}>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "485px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/61b778888621822d522ecad06694c459/44c61/day-3-after.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABWUlEQVQoz42Ry0rDQBSG+yoiLrQuLEVBxOAD6E7sRnCtuBGKVJS0VnRX8AWKC99AEUHES0WwVlCRIiKiBbUXkslckjSZzEUaqFBTGs/unPm/83Pmj3iexwOFMSaEGABAiAghCCPGWFAW4ZzLQGGMiGXjJqXUtiwCERZCBGVdYV9Hm7Ty+PwOLUe0R6GwaLX0s4x2Z6qZicT6ZSJ79/QGJXdN0/rj3wn7b8I1jdy0nuyrbU3Nqxdji4W5TPGrBghGrNOpE+ZMSumWT7XUEFBH6lllVr2ZXCkqy+cnpYaUknERBj8caalBXY0b6djxzuqCehZbuj28/g6BOWO2S63ah54d19eienrUTkerm0p+O1dpuP5ZPWDOga5hhzn3B7oa15L9jeQA3BgWpf3fvwyLyl/P66/O1V6zkOfVFxHw7JFzS8ul9Px4WXvdv2EpbdsxAKAmNDHqjkr5A6/9laDPStpSAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "leveraged ETFs and volatility",
                "title": "leveraged ETFs and volatility",
                "src": "/static/61b778888621822d522ecad06694c459/44c61/day-3-after.png",
                "srcSet": ["/static/61b778888621822d522ecad06694c459/5a46d/day-3-after.png 300w", "/static/61b778888621822d522ecad06694c459/44c61/day-3-after.png 485w"],
                "sizes": "(max-width: 485px) 100vw, 485px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div><div css={`flex:1;`}>
      </div></div>
    <h2>{`Leveraged ETF Mechanics`}</h2>
    <p>{`Leveraged ETFs are complex products, and it is important to understand their inner mechanics before using them. Let's walk through an example:`}</p>
    <ul>
      <li parentName="ul">{`On day 1, we buy a 3x leveraged ETF for $100. This implies taking a loan for $200 so that we control assets worth $300.`}</li>
      <li parentName="ul">{`On day 2, the value of our assets rises by 10%. Our loan is not affected by this, so our equity increases by 30%. Nice! But because our equity grew faster than the assets, our leverage is now less than 3x.`}</li>
      <li parentName="ul">{`The ETF now performs its `}<em parentName="li">{`daily reset`}</em>{` to adjust the leverage back to 3x. To do so, the ETF purchases additional shares resulting in a total of $390 in assets.`}</li>
      <li parentName="ul">{`On day 3, our assets lose 10% of their value. Again, our loan is not affected by this, so our equity decreases by 30%. Similar to before, our equity moved faster than the total asset value, and our leverage is now more than 3x.`}</li>
      <li parentName="ul">{`Again, the ETF performs its daily reset. This time, the ETF sells shares to adjust the total assets to $273. With this exposure, the leverage is back to 3x.`}</li>
    </ul>
    <h2>{`Volatility Decay`}</h2>
    <p>{`Even though the market (almost) returned to where our example started, our investment in the 3x ETF lost 9% of its value. Is our math incorrect? Unfortunately, it is not. Leveraged ETFs promise their performance only for a single day but not for longer periods. The money lost in our example is a consequence of the ETF's daily reset leading to a `}<em parentName="p">{`path dependency`}</em>{` called `}<em parentName="p">{`volatility decay`}</em>{` or `}<em parentName="p">{`beta slippage`}</em>{`. This decay increases with rising volatility and is dependent on the path the price takes.`}</p>
    <p>{`Because of this decay, leveraged ETFs must be used with extreme caution and require constant supervision. In particular, and as illustrated by our example, we need to avoid holding leveraged ETFs in times of high volatility. Therefore, leveraged ETFs are not suitable for buy-and-hold portfolios. Instead, leveraged ETFs require tactical asset allocation.`}</p>
    <p>{`Reducing market exposure when volatility rises comes naturally with volatility-targeting strategies. Therefore this type of strategy is a good match for using leveraged ETFs.`}</p>
    <h2>{`Blowing Up the Account`}</h2>
    <p>{`But there is another significant risk to consider: the risk of blowing up the account. Thanks to the leverage, it is possible for losses to exceed our invested equity. Here is an example:`}</p>
    <ul>
      <li parentName="ul">{`Total Asset Value: $300`}</li>
      <li parentName="ul">{`Equity: $100`}</li>
      <li parentName="ul">{`Loan: $200`}</li>
    </ul>
    <p>{`If on the next day the market drops by 33%, the situation is as follows:`}</p>
    <ul>
      <li parentName="ul">{`Total Asset Value: $201`}</li>
      <li parentName="ul">{`Equity: $1`}</li>
      <li parentName="ul">{`Loan: $200`}</li>
    </ul>
    <p>{`We just lost everything. To avoid bankrupting our account, we should not get too greedy but apply leverage sparingly instead.`}</p>
    <p>{`Limiting the leverage has additional benefits: By keeping the total leverage low and constant, we counteract the ETF’s daily reset. This helps to limit the volatility decay.`}</p>
    <h2>{`Avoiding High Volatility`}</h2>
    <p>{`We have seen above why it is important to avoid holding leveraged ETFs through periods of high volatility. But how do we do that?`}</p>
    <p>{`The simplest method to determine volatility is to calculate it from historical data points. Because volatility can only be determined over a period, not a singular moment in time, we introduce lag by doing so. This lag might pose a disadvantage in fast-moving markets, but our experience still shows good results. We, therefore, like to use historical volatility as our main gauge to control market exposure.`}</p>
    <p>{`But what if this lag cannot be tolerated? For these occasions, it is good to have a fail-safe: The `}<em parentName="p">{`VIX`}</em>{` term structure. Based on the pricing of VIX futures pricing, we can determine implied volatility for 30 days and 1 year and their relationship to each other. Typically, VIX futures are in `}<em parentName="p">{`contango`}</em>{`, meaning that later dates are priced higher. But in times of stress, this relationship often reverses, leading to `}<em parentName="p">{`backwardation`}</em>{`. The prices of VIX futures can be seen as a peek into options trader's brains and often help anticipate future moves. Our `}<a parentName="p" {...{
        "href": "/dashboard/indicators"
      }}>{`market indicators`}</a>{` shows a useful chart for the VIX term structure.`}</p>
    <h2>{`Avoiding Deep Drawdowns`}</h2>
    <p>{`Market declines often come in clusters. Therefore, it is a good idea to remove leverage when markets are moving down. How can we achieve that? Like before, we are using two separate mechanisms.`}</p>
    <p>{`The first method is based on the rolling 12-month drawdown. If this indicator peaks, we remove our leverage. This way, we can avoid the outsized losses that can occur due to the unhealthy combination of leverage and volatility decay.`}</p>
    <iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=pnfb&amp;width=100%&amp;height=275" scrolling="no" frameBorder="0" style={{
      "overflow": "hidden",
      "width": "100%",
      "height": "350px"
    }} allowtransparency="true" loading="lazy"></iframe>
    <p>{`Our second method uses macro-economic indicators. While the economy is only loosely related to the stock market, we feel that it is helpful to remove leverage when approaching a recession. There are many different methods of determining recession periods. We like the so-called Sahm-Rule, due to its simplicity. This method is based on unemployment numbers and has worked beautifully since 1960. To reduce the lag, we calculate our indicator from weekly unemployment numbers.`}</p>
    <PortfolioChart portfolio="tt-pick-me-up" mdxType="PortfolioChart" />
    <h2>{`Pick Me Up`}</h2>
    <p>{`We have seen that leveraged ETFs are no silver bullet to boost your portfolio returns. We need to be mindful of their volatility decay, and we need to be cautious not to blow up our account. In particular, we want to:`}</p>
    <ul>
      <li parentName="ul">{`Apply leverage sparingly: 130% total exposure is probably enough.`}</li>
      <li parentName="ul">{`Apply leverage to instruments with limited volatility, e.g., broad indices.`}</li>
      <li parentName="ul">{`Remove leverage when volatility rises too much.`}</li>
      <li parentName="ul">{`Remove leverage when the account suffers large drawdowns.`}</li>
      <li parentName="ul">{`Don't leave the leveraged ETFs unattended: monitor daily.`}</li>
    </ul>
    <p>{`We have combined all of these concepts in our latest premium strategy, `}<em parentName="p">{`Pick Me Up`}</em>{`. This strategy beats the S&P 500 in most years while at the same time offering a lower risk profile.`}</p>
    <ButtonPrimary text="Learn More About Pick Me Up" to="/portfolios/tt-pick-me-up/" mdxType="ButtonPrimary" />
    <br /><br /><br />
    <PortfolioChart portfolio="tt-rain-or-shine" mdxType="PortfolioChart" />
    <h2>{`Rain or Shine`}</h2>
    <p>{`But using leveraged ETFs is not limited to attempts of 'beating the market.' Our `}<em parentName="p">{`Rain or Shine`}</em>{` strategy aims to make investing a smoother experience and let you sleep better at night. To achieve this, we are taking a different approach:`}</p>
    <ul>
      <li parentName="ul">{`start with a conservative and low-volatility asset allocation`}</li>
      <li parentName="ul">{`add an active management layer to further reduce risk`}</li>
      <li parentName="ul">{`leverage up to achieve the desired returns`}</li>
    </ul>
    <p>{`Of course, we are using similar precautions to avoid the risks associated with using a leveraged ETF.`}</p>
    <ButtonPrimary text="Learn More About Rain or Shine" to="/portfolios/tt-rain-or-shine/" mdxType="ButtonPrimary" />
    <br /><br /><br />
    <p>{`We continuously research new portfolios. If you are looking for a more aggressive approach to applying leverage, see our article on `}<a parentName="p" {...{
        "href": "/articles/leveraged-etf-portfolios-2/"
      }}>{`aggressively leveraged ETF portfolios`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      